import { useState, useCallback } from "react"

import Box from "@mui/material/Box"
import Slide from "@mui/material/Slide"
import Input from "@mui/material/Input"
import Button from "@mui/material/Button"
import { useTheme } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import ClickAwayListener from "@mui/material/ClickAwayListener"

import { bgBlur } from "../../theme/styles"

import { Iconify } from "../../components/iconify"

// ----------------------------------------------------------------------

export function Searchbar({ sx, ...other }) {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="oi:lightbulb" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              ...bgBlur({
                color: theme.vars.palette.background.default
              }),
              top: 0,
              left: 0,
              zIndex: 99,
              width: "100%",
              display: "flex",
              position: "absolute",
              alignItems: "center",
              px: { xs: 3, md: 5 },
              boxShadow: theme.customShadows.z8,
              height: {
                xs: "var(--layout-header-mobile-height)",
                md: "var(--layout-header-desktop-height)"
              },
              ...sx
            }}
            {...other}
          >
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="imagine in simple lines.."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    width={20}
                    icon="oi:lightbulb"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
              sx={{ fontWeight: "fontWeightBold" }}
            />
            <Button variant="contained" onClick={handleClose}>
              Generate
            </Button>
          </Box>
        </Slide>
      </div>
    </ClickAwayListener>
  )
}
