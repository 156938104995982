//import "./global.css"
import "./index.css"
import { Router } from "./routes/sections"
import { useScrollToTop } from "./hooks/use-scroll-to-top"
import { ThemeProvider } from "./theme/theme-provider"
import { AuthProvider } from "./AuthProvider"
export default function App() {
  useScrollToTop()
  return (
    <AuthProvider>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
    </AuthProvider>
  )
}