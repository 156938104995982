// ----------------------------------------------------------------------

export const _workspaces = [
  {
    id: 'team-1',
    name: 'MidVector free',
    logo: `/assets/icons/workspaces/logo-1.webp`,
    plan: 'Free',
  },
  // {
  //   id: 'team-2',
  //   name: 'Team 2',
  //   logo: `/assets/icons/workspaces/logo-2.webp`,
  //   plan: 'Pro',
  // },
  // {
  //   id: 'team-3',
  //   name: 'Team 3',
  //   logo: `/assets/icons/workspaces/logo-3.webp`,
  //   plan: 'Pro',
  // },
];