import React from 'react';
import ReactDOM from "react-dom/client"
import { Suspense, StrictMode } from "react"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import App from "./app.jsx"

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
)

