import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { varAlpha } from "../theme/styles";
import { AuthLayout } from "../layouts/auth";
import { DashboardLayout } from "../layouts/dashboard";
import ProtectedRoute from "../components/ProtectedRoute";
// ----------------------------------------------------------------------

export const DashPage = lazy(() => import("../pages/styles"));
export const DesignPage = lazy(() => import("../pages/design"));
export const BillingPage = lazy(() => import("../pages/billing"));
//export const SignInPage = lazy(() => import("../pages/sign-in"))
export const LandingPage = lazy(() => import("../pages/Landing"));
export const Pricing = lazy(() => import("../pages/Pricing"));
export const SignUp = lazy(() => import("../pages/Signup"));
export const ResetPassword = lazy(() => import("../pages/ResetPassword"));
export const SignIn = lazy(() => import("../pages/Signin"));
export const Page404 = lazy(() => import("../pages/page-not-found"));
export const ContactUs = lazy(() => import("../pages/ContactUs"));

// ----------------------------------------------------------------------

const renderFallback = (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flex="1 1 auto"
  >
    <LinearProgress
      sx={{
        width: 1,
        maxWidth: 320,
        bgcolor: (theme) =>
          varAlpha(theme.vars.palette.text.primaryChannel, 0.16),
        [`& .${linearProgressClasses.bar}`]: { bgcolor: "text.primary" },
      }}
    />
  </Box>
);

export function Router() {
  return useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense fallback={renderFallback}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { path: "design", element: <DashPage /> },
        { path: "billing", element: <BillingPage /> },
        { path: "dashboard", element: <DesignPage /> },
        { path: "contact-us", element: <ContactUs /> },
      ],
    },
    {
      path: "Signin",
      element: <SignIn />,
    },
    {
      path: "create-account",
      element: <SignUp />,
    },
    {
      path: "reset",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "pricing",
      element: <Pricing />,
    },
    {
      path: "contact-us",
      element: <ContactUs />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
