import React from 'react';
import { useAuth } from "../AuthProvider";

//const { currentUser } = useAuth();
import {
  _id,
   _styleNames,
} from './_mock';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------


export const _styles = [...Array(16)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: _id(index),
    name: _styleNames(index),
    coverUrl: `/assets/images/product/img-${setIndex}.webp`,
  };
});

// ----------------------------------------------------------------------

export const _langs = [
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/flags/ic-flag-en.svg',
  },
  {
    value: 'de',
    label: 'German',
    icon: '/assets/icons/flags/ic-flag-de.svg',
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/assets/icons/flags/ic-flag-fr.svg',
  },
];

// ----------------------------------------------------------------------




export const _notifications = [

 
];
