export const _id = index => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index}`

export const _boolean = index =>
  [
    true,
    false,
    true,
    false,
    true,
    true,
    true,
    false,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    false,
    true,
    false,
    false,
    false,
    true,
    true,
    false
  ][index]

export const _styleNames = index =>
  [
    "Flat Minimal",
    "Cute kids",
    "Flat Shaded",
    "Flat Icon",
    "Flat Isomeric",
    "Flat Stroke",
    "Cute Stroke",
    "Funky Mascot",
    "Shaded Mascot",
    "Shaded Sticker",
    "Flat background",
    "Nike Air Zoom BB NXT",
    "Nike Air Force 1 07 LX",
    "Nike Air Force 1 Shadow SE",
    "Nike Air Zoom Tempo NEXT%",
    "Nike DBreak-Type"
  ][index]
