import { Label } from "../components/label";
import { SvgColor } from "../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    width="100%"
    height="100%"
    src={`/assets/icons/navbar/${name}.svg`}
  />
);

export const navData = [
  {
    title: "Ideate",
    path: "/dashboard",
    icon: icon("idea"),
  },
  {
    title: "Styles",
    path: "/design",
    icon: icon("arcticons--thunderbird"),
  },
  {
    title: "Billing",
    path: "/Billing",
    icon: icon("et--wallet"),
  },
  {
    title: "Contact-us",
    path: "/contact-us",
    icon: icon("ic-lock"),
  },
];
