// src/components/ProtectedRoute.js
import React from "react";
import { Routes,Route, Navigate } from "react-router-dom";
import { useAuth } from "../AuthProvider"; // Adjust import path as needed

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { currentUser } = useAuth();

  return (
    <Routes>
    <Route
      {...rest}
      element={currentUser ? <Element /> : <Navigate to="/signin" />}
    />
    </Routes>
  );
};

export default ProtectedRoute;
