// ----------------------------------------------------------------------

/**
 * Usage:
 * ...hideScrollX,
 * ...hideScrollY,
 */
export const hideScrollX = {
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowX: "auto",
    "&::-webkit-scrollbar": { display: "none" }
  }
  
  export const hideScrollY = {
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowY: "auto",
    "&::-webkit-scrollbar": { display: "none" }
  }
  
  /**
   * Usage:
   * ...textGradient(`to right, ${theme.vars.palette.text.primary}, ${alpha(theme.vars.palette.text.primary, 0.2)}`
   */
  export function textGradient(color) {
    return {
      background: `linear-gradient(${color})`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      color: "transparent"
    }
  }
  
  export function bgGradient({ color, imgUrl }) {
    if (imgUrl) {
      return {
        background: `linear-gradient(${color}), url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center"
      }
    }
    return { background: `linear-gradient(${color})` }
  }
  
  export function bgBlur({ color, blur = 6, imgUrl }) {
    if (imgUrl) {
      return {
        position: "relative",
        backgroundImage: `url(${imgUrl})`,
        "&::before": {
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 9,
          content: '""',
          width: "100%",
          height: "100%",
          backdropFilter: `blur(${blur}px)`,
          WebkitBackdropFilter: `blur(${blur}px)`,
          backgroundColor: color
        }
      }
    }
    return {
      backdropFilter: `blur(${blur}px)`,
      WebkitBackdropFilter: `blur(${blur}px)`,
      backgroundColor: color
    }
  }
  